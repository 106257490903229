import React from 'react'
import styled from 'styled-components'

const ServicesGridHeaderWrapper = styled.div`
  background-color: white;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 20px;
  }
`

const ServicesGridHeaderChild = styled.h2`
  max-width: 300px;
  margin: 0 auto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondary};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 24px;
    line-height: 29px;
    max-width: 310px;
  }
`
interface Props {
  children: React.ReactNode
}

const ServicesGridHeader: React.FC<Props> = ({ children }) => (
  <ServicesGridHeaderWrapper>
    <ServicesGridHeaderChild>{children}</ServicesGridHeaderChild>
  </ServicesGridHeaderWrapper>
)

export default ServicesGridHeader
