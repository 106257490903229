import { servicesLinks } from './menu'

type ServiceItem = {
  label: string
  url: string
  image: string
}

export type ServiceItems = ServiceItem[]

const services: ServiceItems = [
  {
    label: 'reCover',
    url: servicesLinks.recover,
    image: '/images/services_save/recover.jpg',
  },
  {
    label: 'Accessoires',
    url: servicesLinks.accessoires,
    image: '/images/services_save/Accessoires-smartphones.jpg',
  },
  {
    label: 'Smartphones reconditionnés',
    url: servicesLinks.reconditionnes,
    image: '/images/services_save/smartphones-reconditionnes.jpg',
  },
  {
    label: 'Prêt si immobilisation',
    url: servicesLinks.pret,
    image: '/images/services_save/pret-si-immobilisation.jpg',
  },
  {
    label: 'Reprise & recyclage',
    url: servicesLinks.rachat,
    image: '/images/services_save/rachat-et-recyclage.jpg',
  },
  {
    label: 'Sauvegarde & transfert de données (RGPD)',
    url: servicesLinks.backup,
    image: '/images/services_save/RGPD.jpg',
  },
]

export default services
