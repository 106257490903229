import styled from 'styled-components'

const ServicesGridDescription = styled.p`
  background-color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  display: none;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    display: block;
  }
`

export default ServicesGridDescription
